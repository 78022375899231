import _ from 'lodash';
import { EDoctorPermissions } from '../../../helpers/constants';
import { EDoctorRequestPrivacy, VENDORS_TYPES } from '../../helpers/groups';

export const TIME_OPTIONS = Object.freeze({
    all: 'all',
    today: 'today',
    since_yesterday: 'sinceYesterday',
    last_3_days: 'last3days',
    last_week: 'lastWeek',
    last_month: 'lastMonth',
    last_quarter: 'lastQuarter',
    rangeFilter: 'created-at-date-range-filter',
});

export const LIST_ORDER = Object.freeze({
    asc: 'asc',
    desc: 'desc',
});

export const GENDER_OPTIONS = Object.freeze({
  genderAll: 'genderAll',
  female: 'female',
  male: 'male',
});

export const BABY_AGE_OPTIONS = Object.freeze({
    ageAll: 'ageAll',
    underThreeMonths: 'underThreeMonths',
    from4Under12Months: 'from4Under12Months',
    from1Under5Years: 'from1Under5Years',
    from6Under12Years: 'from6Under12Years',
    from13Under18Years: 'from13Under18Years',
    from19Under60Years: 'from19Under60Years',
    over60Year: 'over60Year',
});

export const STATUS_FILTER_OPTIONS = Object.freeze({
    filterForAll: 'filterForAll',
    status1: 'status1',
    status2: 'status2',
    status3: 'status3',
    status33: 'status33',
    status4: 'status4',
});

export const STATUS_FILTER_OPTIONS2 = Object.freeze({
    filterForAll: 'filterForAll',
    status3: 'status3',
    status9: 'status9',
    status33: 'status33',
    status37: 'status37',
    status39: 'status39',
    status46: 'status46',
    status54: 'status54',
    status90: 'status90',
    status99: 'status99',
});

export const STATUS_COLOR_FILTER_OPTIONS2 = Object.freeze({
    filterForAll: '#E77F20',
    status3: '#D6341F',
    status9: '#E77F20',
    status33: '#60800A',
    status37: '#E77F20',
    status39: '#60800A',
    status46: '#E77F20',
    status54: '#E77F20',
    status90: '#60800A',
    status99: '#60800A',
});

export const REPORT_OPTIONS = Object.freeze({
    filterForAll: 'filterForAll',
    noReport: 'noReport',
    awaitingReport: 'awaitingReport',
    reported: 'reported',
});

export const REPORT_OPTIONS_COLOR = Object.freeze({
    filterForAll: '#E77F20',
    noReport: '#272733',
    awaitingReport: '#65BED1',
    reported: '#60800A',
});

export const DEFAULT_USER_STATUSES_ARR = ['status33','status3','status2', 'status1'];
export const FULL_STATUS_ARR = ['filterForAll', 'status1', 'status2', 'status3', 'status33', 'status4'];

export const filterByStatus = (value, array) => {
    return array.filter((elem) => value.some((element) => `status${elem.status}` === element))
};

export const sortByCustom = (type, flow, array) => {
    const arr = _.orderBy(array, ['isPrivate', type], [LIST_ORDER.desc, flow]);
    return arr;
};

export const makeStatusFilterItems = (permission, vendor) => {
  if (vendor === VENDORS_TYPES.yeledoctor) {
    return [
      { value: STATUS_FILTER_OPTIONS.filterForAll, status: false },
      { value: STATUS_FILTER_OPTIONS.status1, status: false },
      { value: STATUS_FILTER_OPTIONS.status2, status: false },
      { value: STATUS_FILTER_OPTIONS.status3, status: false },
      { value: STATUS_FILTER_OPTIONS.status33, status: false },
      { value: STATUS_FILTER_OPTIONS.status4, status: false },
    ];
  } else if (permission !== EDoctorPermissions.user) {
    return [
      { value: STATUS_FILTER_OPTIONS.filterForAll, status: false },
      { value: STATUS_FILTER_OPTIONS.status1, status: false },
      { value: STATUS_FILTER_OPTIONS.status2, status: false },
      { value: STATUS_FILTER_OPTIONS.status3, status: false },
      { value: STATUS_FILTER_OPTIONS.status33, status: false },
      { value: STATUS_FILTER_OPTIONS.status4, status: false },
    ]
  }
}

export const whichPermission = (privacy, patient) => {
  switch (privacy) {
    case EDoctorRequestPrivacy.private:
      return { ...patient, isPrivate: true };
    case EDoctorRequestPrivacy.other:
      return { ...patient, isOthers: true, isPrivate: false };
    case EDoctorRequestPrivacy.shared:
    default:
      return { ...patient, isShared: true, isPrivate: false };
  }
}